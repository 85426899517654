'use strict';

const iconCodepen = document.querySelector('#iconCodepen');
const iconDiscord = document.querySelector('#iconDiscord');
const iconLinkedIn = document.querySelector('#iconLinkedIn');
const iconGitHub = document.querySelector('#iconGitHub');

const screenButton = document.querySelector('#screenButton');

iconCodepen?.addEventListener('click', () => window.open('https://codepen.io/dui77'));
iconDiscord?.addEventListener('click', () => window.open('https://discordapp.com/users/Susi#7774'));
iconLinkedIn?.addEventListener('click', () => window.open('https://www.linkedin.com/in/susan-labude/'));
iconGitHub?.addEventListener('click', () => window.open('https://github.com/dui77'));

screenButton?.addEventListener('click', () => window.open('../contact.html', '_self'));